import { mapActions } from 'vuex';
import roundNumber from './roundNumber';

export default {
  mounted() {
    this.triggerPreview();
  },

  mixins: [roundNumber],

  data() {
    return {
      errorLetter: false,
      wrongChar: false,
      tooLong: false,
      mustStand: false,
      cartAnimation: false,
      activeMsg: false,
      ready: true,
      showModal: false,
      errorLetterTwins: false,
      wrongCharTwins: false,
      tooLongTwins: false,
      key: 0,
      colors: [],
      typos: [],
      flux: [],
      options: [],
      isMobile: false,
      photoOption: false,
      versionOption: undefined,
    };
  },

  computed: {
    name() {
      return this.product.name;
    },

    fontList() {
      const fonts = [];
      this.typos.forEach(item => {
        fonts.push(item.name);
      });
      return fonts;
    },

    priceTotal() {
      let total = 0;

      if (this.product.options.length > 0) {
        Object.keys(this.product.options).forEach(option => {
          total +=
            this.product.quantity * this.product.options[option].price;
        });
      }

      return this.roundNumber(
        total + this.product.quantity * this.productPrice,
      );
    },

    filteredOptions() {
      return this.options.filter(option => option.visible);
    },

    optionPhoto() {
      return this.options.filter(
        option => option.name === 'photo' || option.name === 'no-photo',
      );
    },
  },

  methods: {
    ...mapActions({
      addCart: 'shop/addCart',
    }),

    changeTypo(typo) {
      this.product.typo = { name: typo.name, title: typo.title };

      this.$nextTick(() => this.triggerPreview());
    },

    changeColor(color) {
      this.product.color = {
        name: color.name,
        title: color.title,
        hexa: color.hexa,
      };

      this.$nextTick(() => this.triggerPreview());
    },

    hovered(isHovered, index) {
      this.colors[index].isHovered = isHovered;
    },

    changePrice(tab, value) {
      this.$set(tab, 'active', value);
    },

    changeName(newName) {
      if (this.tooLong) this.tooLong = !this.tooLong;
      if (this.wrongChar) this.wrongChar = !this.wrongChar;
      if (this.errorLetter) this.errorLetter = !this.errorLetter;
      this.$set(this.product, 'name', newName);

      this.$nextTick(() => this.triggerPreview(true));
    },

    handleError(error) {
      if (error === 'toolong') this.tooLong = true;
      if (error === 'wrongchar') this.wrongChar = true;
      if (error === 'downletter') this.errorLetter = true;
    },

    changeNameBis(newName) {
      if (this.tooLongTwins) this.tooLongTwins = !this.tooLongTwins;
      if (this.wrongCharTwins) this.wrongCharTwins = !this.wrongCharTwins;
      this.$set(this.product, 'name2', newName);

      this.$nextTick(() => this.triggerPreview(true));
    },

    handleErrorTwins(error) {
      if (error === 'toolong') this.tooLongTwins = true;
      if (error === 'wrongchar') this.wrongCharTwins = true;
      if (error === 'downletter') this.errorLetterTwins = true;
    },

    addOption(shortname) {
      const idOption = this.findIndexInData(
        this.options,
        'shortname',
        shortname,
      );

      const isAlready = this.findIndexInData(
        this.product.options,
        'shortname',
        shortname,
      );

      if (isAlready > -1) {
        this.product.options.splice(isAlready, 1);
      } else {
        this.product.options.push(this.options[idOption]);
      }
    },

    checkOption(name, shortname) {
      const idOption = this.findIndexInData(this.options, 'name', name);
      const isAlready = this.findIndexInData(
        this.product.options,
        'shortname',
        shortname,
      );

      if (isAlready > -1) {
        this.product.options.splice(isAlready, 1);
      }

      this.$nextTick(() => {
        this.product.options.push(this.options[idOption]);
        this.photoOption = true;
      });
    },

    sendDatas() {
      setTimeout(() => {
        this.cartAnimation = true;
        this.showModal = true;
        const { product } = this;
        product.price = this.productPrice;
        product.totalPrice = this.priceTotal;

        this.addCart(JSON.parse(JSON.stringify(product)));

        setTimeout(() => {
          this.cartAnimation = false;
        }, 3000);
      }, 200);
    },

    checkStand() {
      const id = this.findIndexInData(this.options, 'shortname', 'stand');
      this.options[id].active = true;
      this.errorLetter = false;
      this.mustStand = true;
      this.addOption('stand');
    },

    noStand() {
      this.errorLetter = false;
      this.mustStand = true;
    },

    triggerPreview(loading = false) {
      if (!this.product.typo) {
        return;
      }

      this.drawCanvas({
        target: [
          {
            name: this.product.name,
            container: this.$refs.preview,
          },
        ],
        typo: this.product.typo.name,
        color: this.product.color.hexa,
        thickness: this.product.thickness
          ? this.product.thickness.name
          : undefined,
        loading,
      });
    },

    changePersonnalisation(e) {
      this.product.message = e;
    },

    zoom() {
      this.$refs.thumbnail.classList.toggle('thumbnail--zoom');
    },

    closeModal() {
      this.showModal = false;
    },

    decrement() {
      if (this.product.quantity > 1) {
        const qty = this.product.quantity - 1;
        this.$set(this.product, 'quantity', qty);
      }
    },

    increment() {
      const qty = this.product.quantity + 1;
      this.$set(this.product, 'quantity', qty);
    },

    updateQty(ev) {
      const qty = Math.abs(parseInt(ev.target.value, 10));
      this.$set(this.product, 'quantity', qty);
    },

    changeQty(value) {
      this.$set(this.product, 'quantity', value.qty);
    },

    resized(e) {
      this.isMobile = e && e.clientWidth < 768;
    },

    today() {
      return new Date().toISOString().split('T')[0];
    },
  },
};
