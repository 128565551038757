import axios from 'axios';
import qs from 'qs';
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/browser';
import arrActions from './arrActions';
import formatDate from './formatDate';

export default {
  mixins: [arrActions, formatDate],

  computed: {
    ...mapGetters({
      env: 'app/env',
      baseurl: 'app/baseurl',
      url: 'app/url',
    }),
  },

  methods: {
    convertTo12Hour(param) {
      const [hour, minute] = param.split(':');

      return hour > 12 ? `(${hour - 12}:${minute} PM)` : `(${hour}:${minute} AM)`;
    },

    async trello(item, orderNumber, order, isMultiple) {
      try {
        const labels = [];
        let info = '';
        let info2 = '';
        let message = '';
        let symbole = '';
        let perso = '';

        if (order.cartAddress.address.country === 'France') {
          labels.push('5cf183d691d0c2ddc57b6b87');
        } else {
          labels.push('5cf183d691d0c2ddc57b6b7e');
        }

        const indexPhoto = this.findIndexInData(item.options, 'shortname', 'photo');
        if (indexPhoto > -1 && item.options[indexPhoto].name === 'photo') {
          labels.push('5cf183d691d0c2ddc57b6b88');
        }

        if (isMultiple) {
          labels.push('5cf183d691d0c2ddc57b6b7d');
        }

        const address = `**Adresse**
        ${order.cartAddress.address.firstname} ${order.cartAddress.address.lastname}
        ${order.cartAddress.address.number ? order.cartAddress.address.number : ''} ${order.cartAddress.address.street} ${order.cartAddress.address.line}
        ${order.cartAddress.address.zipcode} ${order.cartAddress.address.city} ${order.cartAddress.address.country}`;

        if (item.informations) {
          info = `**Informations**
          Nom - ${item.name} ${item.name2 ? item.name2 : ''}
          ${item.informations.date ? `Date - ${this.stringPrettyDate(item.informations.date)}` : ''}
          ${item.date ? `Date - ${this.stringPrettyDate(item.date)}` : ''}
          ${item.informations.height ? `Taille - ${item.informations.height} cm` : ''}
          ${item.informations.weight ? `Poids - ${item.informations.weight} kg` : ''}
          ${item.informations.hour ? `Heure - ${item.informations.hour} (${this.convertTo12Hour(item.informations.hour)})` : ''}
          ${item.informations2 && item.informations2.height ? `Taille - ${item.informations2.height} cm` : ''}
          ${item.informations2 && item.informations2.weight ? `Poids - ${item.informations2.weight} kg` : ''}
          ${item.informations2 && item.informations2.hour ? `Heure - ${item.informations2.hour} (${this.convertTo12Hour(item.informations2.hour)})` : ''}
          ${item.informations.godfather ? `\r\n Parrain - ${item.informations.godfather}` : ''}
          ${item.informations.godmother ? `Marraine - ${item.informations.godmother}` : ''} `;
        }

        if (item.date) {
          info = `**Informations**
          ${item.date ? `Date - ${this.stringPrettyDate(item.date)}` : ''}`;
        }

        if (item.message) {
          message = `**Message**
          Personnalisation - ${item.message}`;
        }

        if (item.startDate || item.endDate) {
          info2 = `**Informations**
          ${item.startDate ? `Année(s) - ${item.startDate}` : ''} ${item.endDate ? `- ${item.endDate}` : ''} `;
        }

        if (item.symbole) {
          symbole = `**Symbole**
          ${item.symbole.name ? item.symbole.name : 'Pas de symbole'}`;
        }

        if (item.colorSnowflake) {
          perso = `**Personnalisation**
          ${item.colorSnowflake ? `Couleur du flocon - ${item.colorSnowflake.title}` : ''}`;
        } else if (item.colorPlate) {
          perso = `**Personnalisation**
          ${item.colorPlate ? `Couleur de la plaquette - ${item.colorPlate.title}` : ''}`;
        } else if (item.colorBall) {
          perso = `**Personnalisation**
          ${item.colorBall ? `Couleur de la boule - ${item.colorBall.title}` : ''}`;
        }

        if (item.colorArk1) {
          perso = `**Personnalisation**
          Couleur prénom - ${item.color.title}
          Couleur de l'arche extérieure - ${item.colorArk1.title}
          Couleur de l'arche centrale - ${item.colorArk2.title}
          Couleur de l'arche intérieure - ${item.colorArk3.title}`;
        }

        const data = {
          name: `${item.quantity}x ${item.type} ${item.name ? item.name : ''} ${item.name2 ? item.name2 : ''} ${item.thickness ? item.thickness.title : ''} ${item.color ? item.color.title : ''} ${item.typo ? item.typo.title : ''} ${item.width ? item.width.title : ''}`,
          desc: `**Commande** \r\n \
          ${orderNumber} \

          \r\n ${address} \

          \r\n ${info} \

          \r\n ${info2} \

          \r\n ${message} \

          \r\n ${symbole} \

          \r\n ${perso} \

          ${item.options.length > 0 ? '\r\n **Options** ' : ''}
          ${item.options.map(option => `- ${option.title}`).join('\r')}`.trim(),
          idLabels: labels.join(','),
        };

        const response = await axios.post(
          `${this.baseurl}/orders/add-to-trello`,
          qs.stringify(data),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        );

        return response;
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  },
};
